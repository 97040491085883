interface IHistorySearchData {
  name: string;
  datetime: Date;
}
interface IHistorySearch {
  set(list: string): void;
  add(name: string): void;
  get(): IHistorySearchData[] | null;
  getRaw(): string | null;
}

const historySearch: IHistorySearch = {
  set(listString: string) {
    localStorage.setItem("historySearch", listString); 
  },
  add(name: string) {
    const data = localStorage.getItem("historySearch");
    let newArray: IHistorySearchData[] = [];
    if (data && data != `""`) {
      newArray = JSON.parse(data) as IHistorySearchData[];
    }
    newArray.unshift({
      name,
      datetime: new Date()
    })

    if (newArray.length > 30)
      newArray.length = 30;

    return localStorage.setItem("historySearch", JSON.stringify(newArray));
  },
  get() {
    const data = localStorage.getItem("historySearch");
    if (data && data != `""`) {
      return JSON.parse(data) as IHistorySearchData[];
    } else {
      return [];
    }
  },
  getRaw() {
    const data = localStorage.getItem("historySearch");
    return data;
  }
};

export default historySearch;
