import React from 'react';
import styled from '@emotion/styled';

import Input, { IInput } from './Input';

export interface IInputWithLabel extends IInput {
  label: string;
  error?: boolean;
  errorMessage?: string;
  info?: string;
}

const InputWithLabel = ({
  label,
  error,
  errorMessage,
  info,
  ...props
}: IInputWithLabel) => {
  return (
    <Wrapper>
      <Info>
        <Label>{label}</Label>
        {error ? (
          <Error>{errorMessage}</Error>
        ) : (
          info && <InfoText>{info}</InfoText>
        )}
      </Info>
      <Input error={error} {...props} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.span`
  display: block;
  margin-bottom: 10px;
  opacity: 0.8;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  color: #1e1e1e;
  html[color-mode='dark'] & {
    color: white;
    opacity: 0.8;
  }
`;

const Error = styled.span`
  font-size: 14px;
  line-height: 1.29;
  text-align: right;
  color: #d51414;
  max-width: 70%;
  margin-bottom: 5px;
`;

const InfoText = styled.span`
  font-size: 14px;
  line-height: 1.86;
  text-align: right;
  color: #0a84ff;
`;

export default InputWithLabel;
