import React from 'react';
import styled from '@emotion/styled';

import Header from './Header';
import Footer from './Footer';
import css from '@emotion/css';

interface IServiceWrapper {
  className?: string;
  children?: React.ReactNode;
  isFooterRendered?: boolean;
  isHeaderRendered?: boolean;
  containerStyles?: React.CSSProperties;
  headerPosition?: string;
  bodyBackgroundColor?: string;
}

const ServiceWrapper: React.FC<IServiceWrapper> = ({
  className,
  children,
  isHeaderRendered = true,
  isFooterRendered = true,
  containerStyles = {},
  headerPosition = 'relative',
  bodyBackgroundColor = 'transparent',
}) => {
  return (
    <Container style={containerStyles}>
      {isHeaderRendered && <Header position={headerPosition} />}
      <Body
        className={className}
        headerPosition={headerPosition}
        backgroundColor={bodyBackgroundColor}
      >
        {children}
      </Body>
      {isFooterRendered && <Footer />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Body = styled.div<{ headerPosition: string; backgroundColor: string }>`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 75px);
  ${({ headerPosition }) =>
    headerPosition === 'fixed' &&
    css`
      margin-top: 75px;
    `}

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
      html[color-mode='dark'] & {
        background: transparent !important;
      }
    `}
  html[color-mode='dark'] & {
    background: transparent !important;
  }
  overflow-x: hidden;
`;

export default ServiceWrapper;
