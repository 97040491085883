import { useHistory } from 'react-router-dom';
import React from 'react';
import styled from '@emotion/styled';

import MenuIcon from '../assets/icons/menu.svg';

interface ITitleWithBack {
  onClick?: () => void;
}

const TitleWithBack: React.FC<ITitleWithBack> = ({ children, onClick }) => {
  const history = useHistory();

  const onClickBack = onClick ? onClick : () => history.goBack();

  return (
    <Wrapper>
      <div onClick={onClickBack}>
        <MenuIcon />
      </div>
      <Title>{children}</Title>
    </Wrapper>
  );
};

export default TitleWithBack;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 26px;
`;

const Title = styled.span`
  margin-left: 15px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  color: #1e1e1e;
  html[color-mode='dark'] & {
    color: #ffffff;
  }
`;
