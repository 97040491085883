import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

interface ISupportRow {
  id: number;
  title: string;
  date: string;
  active: boolean;
}

const SupportRow = ({ id, title, date, active }: ISupportRow) => {
  return (
    <Container
      className={active ? `support_row_active` : ''}
      to={`/support/${id}`}
    >
      <Title>{title}</Title>
      <LeftWrap>
        <Date>{date}</Date>
        <Info>{active ? '답변대기' : '답변완료'}</Info>
      </LeftWrap>
    </Container>
  );
};

const Container = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 7px;
  background-color: #ffffff;
  padding: 25px 47px 25px 66px;
  margin-bottom: 10px;
  text-decoration: none;
  color: inherit;

  &:last-child {
    margin-bottom: 0;
  }

  html[color-mode='dark'] & {
    background-color: #1b1f28;
  }

  @media screen and (max-width: 760px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 25px;
  }
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  color: #1e1e1e;
  .support_row_active & {
    opacity: 0.5;
    html[color-mode='dark'] & {
      opacity: 1;
    }
  }
  html[color-mode='dark'] & {
    color: white;
    opacity: 0.5;
  }
`;

const LeftWrap = styled.div`
  display: flex;

  @media screen and (max-width: 760px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Date = styled.span`
  font-size: 14px;
  line-height: 1.14;
  color: #1e1e1e;

  .support_row_active & {
    opacity: 0.5;

    html[color-mode='dark'] & {
      opacity: 1;
    }
  }

  html[color-mode='dark'] & {
    color: white;
    opacity: 0.5;
  }

  @media screen and (max-width: 760px) {
    margin-top: 5px;
  }
`;

const Info = styled.span`
  font-size: 14px;
  line-height: 1.14;
  text-align: center;
  color: #0a62ba;
  margin-left: 105px;

  .support_row_active & {
    color: #1e1e1e;
  }

  html[color-mode='dark'] & {
    color: #0a62ba;
  }

  .support_row_active & {
    html[color-mode='dark'] & {
      color: #939393;
    }
  }

  @media screen and (max-width: 760px) {
    margin-left: 0;
    margin-top: 21px;
  }
`;

export default SupportRow;
