import styled from '@emotion/styled';

const TextHeader = styled.h1`
  opacity: 0.9;
  font-size: 34px;
  font-weight: bold;
  color: #1e1e1e;

  html[color-mode='dark'] & {
    color: white;
  }

  @media screen and (max-width: 960px) {
    font-size: 28px;
  }

  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
`;

export default TextHeader;
