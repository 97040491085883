import React, {useState} from "react";
import styled from "@emotion/styled";
import css from "@emotion/css";

import {IMenuItem} from "./constants";
import {NavLink} from "react-router-dom";
import auth from "../../utils/auth";
import {useHistory} from "react-router";

const MenuItem: React.FC<IMenuItem> = ({
                                           to = "",
                                           icon,
                                           darkIcon,
                                           menu,
                                           isDarkMode = false,
                                           isUploaderOnly = false,
                                           onClick,
                                       }) => {
    const isDarkIconRendered = isDarkMode && darkIcon;

    if (isUploaderOnly) {
        return (
            <>
                <Container
                    onClick={() =>
                        (document.getElementById("token-form") as HTMLFormElement)?.submit()
                    }
                >
                    <Icon>{isDarkIconRendered ? darkIcon : icon}</Icon>
                    <MenuText>{menu}</MenuText>
                </Container>
                <form
                    action="https://admin.chordscore.com/user/music/index"
                    method="post"
                    target="_blank"
                    id="token-form"
                >
                    <input type="hidden" name="access_token" value={auth.getToken()}/>
                </form>
            </>
        );
    }

    return (
        <>
            {
                to ?
                    <ContainerLink
                        exact
                        to={to}
                        activeClassName="active_menu_item"
                        style={{
                            display:
                                menu === "플레이리스트" && window.innerWidth > 815 ? "none" : "flex",
                        }}
                    >
                        <Icon>{isDarkIconRendered ? darkIcon : icon}</Icon>
                        <MenuText>{menu}</MenuText>
                    </ContainerLink> :
                    <Container onClick={onClick}>
                        <Icon>{isDarkIconRendered ? darkIcon : icon}</Icon>
                        <MenuText>{menu}</MenuText>
                    </Container>
            }
        </>
    );
};

export default MenuItem;

const containerStyles = css`
  display: flex;
  align-items: center;
  margin: 25px 23px 50px 23px;
  cursor: pointer;
  text-decoration: none;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 500px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const ContainerLink = styled(NavLink)`
  ${containerStyles}
`;

const Container = styled.div`
  ${containerStyles}
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  & g {
    opacity: 1;
  }

  & path {
    fill: #1e1e1e;
  }

  html[color-mode="dark"] & {
    & :not(.calendar-plus):not(.calendar-minus):not(.faq) path {
      fill: #ffffff;
    }

    & .calendar-minus,
    & .calendar-plus {
      stroke: #fff;
    }
  }

  .active_menu_item & path {
    fill: rgb(10, 98, 186) !important;
  }

  @media screen and (max-width: 500px) {
    height: 16px;
    width: 16px;
  }
`;

const MenuText = styled.span`
  opacity: 0.8;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  color: #1e1e1e;

  html[color-mode="dark"] & {
    color: white;
    opacity: 1;
  }

  .active_menu_item & {
    color: #0a62ba;

    html[color-mode="dark"] & {
      opacity: 1;
      color: #0a62ba;
    }
  }
`;
