const { parse } = JSON;

interface IAuth {
  clear(key: string): void;
  clearAppStorage(): void;
  get(key: string): JSON;
  setToken(token: string): void;
  getToken(): any;
  setRefreshToken(token: string): void;
  getRefreshToken(): any;
  setEmail(key: string): void;
  getEmail(): any;
  setPhone(key: string): void;
  getPhone(): any;
  setNoticeCount(count: number): void;
  getNoticeCount(): any;
  setRememberMe(rememberMe: boolean): void;
  getRememberMe(): boolean;
  setPayStatus(key: number): void;
  getPayStatus(): any;
}

const auth: IAuth = {
  clear(key) {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }

    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }
    return null;
  },

  clearAppStorage() {
    return localStorage.clear();
  },

  get(key) {
    return parse(localStorage.getItem(key) as string);
  },

  setToken(token) {
    return localStorage.setItem('token', token);
  },

  getToken() {
    return localStorage.getItem('token');
  },

  setRefreshToken(token) {
    return localStorage.setItem('refreshToken', token);
  },

  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  },

  setEmail(email) {
    return localStorage.setItem('email', email);
  },

  getEmail() {
    return localStorage.getItem('email');
  },

  setPhone(phone) {
    return localStorage.setItem('phone', phone);
  },

  getPhone() {
    return localStorage.getItem('phone');
  },

  setPayStatus(payStatus) {
    return localStorage.setItem('payStatus', payStatus.toString());
  },

  getPayStatus() {
    return localStorage.getItem('payStatus');
  },

  setNoticeCount(notice) {
    return localStorage.setItem('notice', notice.toString());
  },

  getNoticeCount() {
    return localStorage.getItem('notice');
  },

  setRememberMe(rememberMe) {
    return localStorage.setItem('rememberMe', rememberMe.toString());
  },

  getRememberMe() {
    return localStorage.getItem('rememberMe') === "true";
  },
};

export default auth;
