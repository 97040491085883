import React from 'react';
import styled from '@emotion/styled';
import Button from './Button';
import ReactDOM from 'react-dom';
import useHiddenBodyOverflow from '../utils/useHiddenBodyOverflow';
import css from "@emotion/css";

interface IModal {
  open: boolean;
  width?: number;
  showCloseIcon?: boolean;
  onClose?: () => void;

  firstButtonLabel?: string;
  onClickFirstButton?: () => void;
  secondButtonLabel?: string;
  secondButtonDisabled?: boolean;
  onClickSecondButton?: () => void;

  title?: string;
  content: string | React.ReactNode;
}

interface IModal extends IModalInfo {
  open: boolean;
}

export interface IModalInfo {
  image?: any;
  title?: string;
  content: string | React.ReactNode;
}

export default ({
  image,
  width,
  open,
  showCloseIcon,
  onClose,
  title,
  content,
  firstButtonLabel,
  onClickFirstButton,
  secondButtonLabel,
  onClickSecondButton,
  secondButtonDisabled,
}: IModal) => {
  useHiddenBodyOverflow(open);

  return open
    ? ReactDOM.createPortal(
      <Modal>
        <AbsoluteContainer>
          <CloseContainer onClick={onClose} />
          <ModalContainer width={width}>
            {showCloseIcon && <CloseIcon onClick={onClose}>×</CloseIcon>}
            <ModalContent>
              {title && <ModalTitle>{title}</ModalTitle>}
              <ModalContentText>{content}</ModalContentText>
            </ModalContent>
            {(firstButtonLabel || secondButtonLabel) && (
              <ButtonWrap>
                {firstButtonLabel && (
                  <StyledButton styleType="blue" onClick={onClickFirstButton}>
                    {firstButtonLabel}
                  </StyledButton>
                )}
                {secondButtonLabel && (
                  <StyledButton
                    styleType="outline"
                    onClick={onClickSecondButton}
                    disabled={secondButtonDisabled}
                  >
                    {secondButtonLabel}
                  </StyledButton>
                )}
              </ButtonWrap>
            )}
          </ModalContainer>
        </AbsoluteContainer>
      </Modal>,
      document.getElementById('root') as HTMLElement,
    )
    : null;
};

const CloseIcon = styled.div`
  background-color: #0077CC;
  width: 40px;
  height: 40px;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
`

const Modal = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: flex-end;
  background-color: rgba(4, 4, 4, 0.7);
  width: 100%;
  height: 100%;
  z-index: 999999;
  top: 0;
  left: 0;
`;

const CloseContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

const ModalContainer = styled.div<{ width?: number }>`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 30px;
  max-width: 450px;
  border-radius: 15px;
  background-color: white;
  z-index: 200;
  html[color-mode='dark'] & {
    background-color: #212632;
  }
  ${({ width }) =>
    width &&
    css`
      max-width: ${width}px !important;
  `}
`;

const AbsoluteContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ModalTitle = styled.h1`
  opacity: 0.8;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #1e1e1e;
  margin-bottom: 26px;
  html[color-mode='dark'] & {
    opacity: 0.9;
    color: white;
  }

  @media screen and (max-width: 960px) {
    font-size: 24px;
    margin-bottom: 62px;
  }

  @media screen and (max-width: 400px) {
    font-size: 20px;
    margin-bottom: 45px;
  }
`;

const ModalContentText = styled.span`
  width: 100%;
  opacity: 0.8;
  line-height: 1.63;
  text-align: center;
  color: #1e1e1e;
  font-size: 14px;

  & > b,
  & > strong {
    font-weight: bold;
    color: #333333;
    html[color-mode='dark'] & {
      color: white;
    }
  }

  html[color-mode='dark'] & {
    color: white;
    opacity: 1;
    /* opacity: 0.6; */
  }
`;

const ButtonWrap = styled.div`
  margin-top: 33px;
  @media screen and (max-width: 960px) {
    margin-top: 39px;
  }

  @media screen and (max-width: 400px) {
    margin-top: 20px;
  }

  display: flex;

  & > *:nth-of-type(2n) {
    max-width: 100px;
    margin-left: 10px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  border-width: 1px;
`;
