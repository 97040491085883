import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';

import SupportRow from '../components/SupportRow';
import TextHeader from '../components/TextHeader';
import {
  StyledServiceWrapper,
  StyledButton,
  TopContents,
  BottomContainer,
  BottomContents,
} from '../components/SectionPage';
import { api } from '../utils/api';
import { IInquiry } from '../types';

const Support = () => {
  const history = useHistory();
  const [data, setData] = useState<Array<IInquiry>>([]);

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: {
          data: { inquiries },
        },
      } = await api.get('/inquiry');
      setData(inquiries);
    };
    fetchData();
  }, []);

  return (
    <StyledServiceWrapper>
      <TopContents>
        <TextHeader>문의/요청하기</TextHeader>
        <StyledButton
          styleType="blue"
          onClick={() => history.push('/support/submit')}
        >
          문의 작성하기
        </StyledButton>
      </TopContents>
      <BottomContainer>
        <BottomContents>
          {!data.length && (
            <EmptyMessage>기존 문의 내용이 없습니다.</EmptyMessage>
          )}
          {data.map((support) => (
            <SupportRow
              key={`support-${support.id}`}
              id={support.id}
              title={support.title}
              date={support.created_at.slice(0, 10)}
              active={support.status ? false : true}
            />
          ))}
        </BottomContents>
      </BottomContainer>
    </StyledServiceWrapper>
  );
};

export default Support;

const EmptyMessage = styled.p`
  opacity: 0.8;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.63;
  text-align: center;
  color: #ffffff;
  margin-top: 168px;

  @media screen and (max-width: 500px) {
    margin-top: 123px;
  }
`;
