import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';

import TitleWithBack from './TitleWithBack';
import {
    SearchBox,
    SearchBoxIcon,
    SearchBoxIconWrap,
    SearchBoxInput,
} from './Search';
import historySearch from '../utils/historySearch';
import {format} from 'date-fns';
import css from '@emotion/css';
import {useHistory} from "react-router-dom";

interface IMobileSearchHeader {
    value: string;
    targetValue: string;
    onChange: (e: any) => void;
    onChangeTarget: (e: any) => void;
    onKeyPress: (e: any) => void;
    onClick?: () => void;
    onClickHistory: (name: string) => void;
}

const MobileSearchHeader: React.FC<IMobileSearchHeader> = ({
                                                               value,
                                                               targetValue,
                                                               onChange,
                                                               onChangeTarget,
                                                               onKeyPress,
                                                               onClick,
                                                               onClickHistory,
                                                           }) => {
    const history = useHistory();
    const [displayHistorySearch, setDisplayHistorySearch] = useState(false);

    useEffect(() => {
        const searchInput = document.getElementById('search-input-mobile');
        searchInput?.addEventListener('focus', (event) => {
            setDisplayHistorySearch(true);
        }, true);
        searchInput?.addEventListener('blur', (event) => {
            setTimeout(() => {
                setDisplayHistorySearch(false);
            }, 10)
        }, true);

    })

    const hideHistorySearch = () => {
        setDisplayHistorySearch(false);
    }

    const onClickHistorySearchItem = (name: string) => {
        let e = {target: {value: name}, key: 'Enter'}
        onChange(e);
        onClickHistory(name);
    }

    return (
        <SearchContainer>
            <TitleWithBack onClick={() => history.push('/')}>검색</TitleWithBack>
            <SearchBoxWrapper>
                <SearchBox>
                    <SearchSelect value={targetValue} onChange={onChangeTarget}>
                        <option value={'mix'}>제목 + 가수</option>
                        <option value={'title'}>제목</option>
                        <option value={'singer'}>가수</option>
                    </SearchSelect>
                    <SearchBoxIconWrap>
                        <SearchBoxIcon/>
                    </SearchBoxIconWrap>
                    <SearchBoxInput
                        id="search-input-mobile"
                        autoComplete="off"
                        placeholder="검색어를 입력하세요."
                        value={value}
                        onChange={onChange}
                        onKeyPress={onKeyPress}
                    />
                </SearchBox>
            </SearchBoxWrapper>
            <HistorySearchWrap display={displayHistorySearch}>
                {historySearch.get()?.map((item, index) => (
                    <HistorySearchItem key={index} onClick={() => onClickHistorySearchItem(item.name)}>
                        <HistorySearchItemName>
                            {index + 1}. {item.name}
                        </HistorySearchItemName>
                        <HistorySearchItemDate>
                            {format(new Date(item.datetime), 'MM/dd')}
                        </HistorySearchItemDate>
                    </HistorySearchItem>
                ))}
            </HistorySearchWrap>
        </SearchContainer>
    );
};

const SearchSelect = styled.select`
  border-radius: 6px;
  background-color: transparent;
  border: 0;
  margin-right: 10px;
  font-size: 14px;
  text-align: center;

  html[color-mode='dark'] & {
    opacity: 0.3;
    color: #fff;
  }
`

const SearchContainer = styled.div`
  width: 100%;
  background-color: #151921;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  padding: 10px 20px;
  padding-top: 18px;
`;

const SearchBoxWrapper = styled.div`
  width: 88vw;
  height: 40px;
  display: flex;
  gap: 10px;

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 6px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const HistorySearchWrap = styled.div<{ display: boolean }>`
  ${({display}) =>
          display == true ? css`display: block;` : css`display: none;`
  }
  width: 88vw;
`;

const HistorySearchItem = styled.div`
  &:first-child {
    padding-top: 20px;
  }

  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px;

  &:hover {
    background-color: #1c2230;
  }
`;

const HistorySearchItemName = styled.p`
`;

const HistorySearchItemDate = styled.p`
`;


export default MobileSearchHeader;
