import { useEffect } from 'react';

export default (condition = true) =>
  useEffect(() => {
    if (condition) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [condition]);
