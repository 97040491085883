import styled from '@emotion/styled';
export { default as SearchBoxIcon } from '../assets/searchbox-icon.svg';

export const SearchBox = styled.div`
  display: flex;
  align-items: center;
  width: 569px;
  height: 40px;
  border-radius: 22.5px;
  background-color: #d8d8d866;
  padding: 0 26px;

  html[color-mode='dark'] & {
    background-color: rgba(216, 216, 216, 0.11);
  }

  @media screen and (max-width: 1500px) {
    width: 309px;
  }

  @media screen and (max-width: 890px) {
    width: 250px;
  }

  @media screen and (max-width: 815px) {
    display: none;
  }
`;

export const SearchBoxIconWrap = styled.div`
  opacity: 0.8;
  margin-right: 11px;
  html[color-mode='dark'] & path {
    fill: #ffffff;
  }
`;

export const SearchBoxInput = styled.input`
  width: 100%;
  font-size: 15px;
  padding: 12px 0;
  border: 0;
  outline: 0;
  background: none;
  &::placeholder {
    color: #1e1e1e4d;
  }
  html[color-mode='dark'] & {
    opacity: 0.3;
    color: white;
    &::placeholder {
      color: white;
    }
  }
`;
