import React from 'react';
import styled from '@emotion/styled';
import css from '@emotion/css';

export interface IInput
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  icon?: string;
  error?: boolean;
}

const Input = ({ error, className, icon, ...props }: IInput) => {
  const Icon = icon && require(`../assets/${icon}-button-icon.svg`).default;
  return (
    <Container error={error} className={className}>
      {icon && (
        <IconWrap>
          <Icon />
        </IconWrap>
      )}
      <StyledInput {...props} />
    </Container>
  );
};

const Container = styled.div<{ error?: boolean }>`
  display: flex;
  width: 100%;
  padding: 0 20px;
  border-radius: 7px;
  background-color: #ebebeb;
  html[color-mode='dark'] & {
    background-color: #2e3137;
  }
  ${({ error }) =>
    error
      ? css`
          border: 1px solid #d51414;
        `
      : css`
          border: 1px solid #ebebeb;
          html[color-mode='dark'] & {
            border: 1px solid #24272d;
          }
        `}
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  html[color-mode='dark'] & path {
    fill: white !important;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 15px 0;
  font-size: 15px;
  line-height: 1.27;
  color: #1e1e1e;
  outline: 0;
  background: none;
  border: 0;
  font-weight: 500;
  html[color-mode='dark'] & {
    color: white;
    &::placeholder {
      color: white;
      opacity: 0.5;
    }
  }
`;

export const TextArea = styled.textarea`
  display: flex;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 7px;
  background-color: #ebebeb;
  font-size: 15px;
  line-height: 1.27;
  color: #1e1e1e;
  outline: 0;
  border: 0;
  font-weight: 500;
  resize: none;
  html[color-mode='dark'] & {
    color: white;
    background-color: #2e3137;

    &::placeholder {
      color: white;
      opacity: 0.5;
    }
  }
`;

export default Input;
