import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { api } from '../utils/api';
import auth from '../utils/auth';
import useSearchParams from '../utils/useSearchParams';

export default () => {
  const history = useHistory();
  const { searchParams } = useSearchParams();

  useEffect(() => {
    const login = async () => {
      const {
        data: {
          data: {
            user: { email, phone },
            total_alarm_count,
          },
        },
      } = await api.get('/user', {
        headers: {
          Authorization: `Bearer ${String(searchParams.access_token)}`,
        },
      });
      auth.setPhone(phone);
      auth.setEmail(email);
      auth.setNoticeCount(total_alarm_count);
      auth.setToken(String(searchParams.access_token));
      auth.setRefreshToken(String(searchParams.refresh_token));
      auth.setRememberMe(true);
      history.push('/');
      window.location.reload();
    };
    login();
  }, [searchParams, history]);

  return null;
};
