import React from "react";

import Profile from "../../assets/icons/profile.svg";
import ProfileDark from "../../assets/icons/profile-dark.svg";
import Notes from "../../assets/icons/notes.svg";
import NotesDark from "../../assets/icons/notes-dark.svg";
import Support from "../../assets/icons/support.svg";
import Faq from "../../assets/icons/faq.svg";
import AlertIcon from '../../assets/alert-icon.svg';
import SupportDark from "../../assets/icons/support-dark.svg";
import Settings from "../../assets/icons/settings.svg";
import SettingsDark from "../../assets/icons/settings-dark.svg";
import AddSubs from "../../assets/icons/calendar-plus.svg";
import DelSubs from "../../assets/icons/calendar-minus.svg";
import Modal from '../Modal';
import {RouteComponentProps} from 'react-router-dom';

export interface IMenuText {
    selected?: boolean;
}

export interface IMenuItem extends IMenuText {
    to?: string;
    icon: React.ReactNode;
    darkIcon?: React.ReactNode;
    menu: string;
    auth?: boolean;
    isDarkMode?: boolean;
    isUploaderOnly?: boolean;
    onClick?: () => void;
}

export const menuList: IMenuItem[] = [
    {
        to: "/auth/profile",
        icon: <Profile/>,
        darkIcon: <ProfileDark/>,
        menu: "프로필 수정",
    },
    // {
    //   to: '/membership',
    //   icon: <Ticket />,
    //   menu: '이용권',
    //   selected: true,
    // },
    {
        to: "/",
        icon: <Notes/>,
        darkIcon: <NotesDark/>,
        menu: "악보등록",
        isUploaderOnly: true,
    },
    // {
    //   to: "/playlist",
    //   icon: <Notes />,
    //   darkIcon: <NotesDark />,
    //   menu: "플레이리스트",
    // },
    {
        to: "/notice",
        icon: <AlertIcon/>,
        darkIcon: <AlertIcon/>,
        menu: "알림"
    },
    {
        to: "/support",
        icon: <Support/>,
        darkIcon: <SupportDark/>,
        menu: "문의/요청사항",
    },
    {
        icon: <AddSubs/>,
        darkIcon: <AddSubs/>,
        menu: "구독 신청",
    },
    {
        icon: <DelSubs/>,
        darkIcon: <DelSubs/>,
        menu: "구독 해지",
    },
    {
        icon: <Faq />,
        darkIcon: <Faq />,
        menu: "FAQ",
    },
    {
        to: "/setting",
        icon: <Settings/>,
        darkIcon: <SettingsDark/>,
        menu: "설정",
    },
];
