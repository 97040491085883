import { useEffect, useState } from 'react';

export default () => {
  const [isDarkModeEnabled, setIsDarkModeEnabled] = useState<boolean>(false);
  useEffect(
    () =>
      setIsDarkModeEnabled(
        document.querySelector('html')?.getAttribute('color-mode') === 'dark',
      ),
    [],
  );

  return isDarkModeEnabled;
};
