import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";

import MenuItem from "./MenuItem";
import {menuList} from "./constants";

import Logout from "../../assets/icons/logout.svg";
import Cancel from "../../assets/icons/cancel.svg";
import Edit from "../../assets/icons/edit.svg";

import useDarkMode from "../../utils/useDarkMode";

import Logo from "../../assets/logo.svg";
import DarkLogo from "../../assets/logo-dark.svg";
import {useHistory} from "react-router";
import auth from "../../utils/auth";
import useHiddenBodyOverflow from "../../utils/useHiddenBodyOverflow";
import {api} from "../../utils/api";
import LinkIcon from "../../assets/naver_cafe.png";
import useWindowSize from "../../utils/useWindowSize";
import Modal from "../Modal";
import InputWithLabel from "../InputWithLabel";
import Button from "../Button";

interface ISideMenu {
    isLoggedIn?: boolean;
    onClickClose: () => void;
}

const SideMenu: React.FC<ISideMenu> = ({
                                           isLoggedIn = false,
                                           onClickClose,
                                       }) => {
    const history = useHistory();
    const isDarkMode = useDarkMode();
    const [isUploader, setIsUploader] = useState<boolean>(false);
    const renderedLogo = isDarkMode ? <DarkLogo/> : <Logo/>;
    const {width: screenWidth = 1980} = useWindowSize();
    const isSmallScreen = screenWidth <= 500;
    const [showSubsModal, setShowSubsModal] = useState<boolean>(false);
    const [isSubs, setIsSubs] = useState<boolean>(false);
    const [isSubsDel, setIsSubsDel] = useState<boolean>(false);
    const [payStatus, setPayStatus] = useState<string>(auth.getPayStatus());
    const [isSendPhoneCert, setIsSendPhoneCert] = useState(false);
    const [phoneCertCount, setPhoneCertCount] = useState(1);
    const [info, setInfo] = useState({
        phone: "",
        phoneCert: "",
    });
    const [error, setError] = useState({
        phone: false,
    });

    const handleChange = (e: any) => {
        e.persist();
        setInfo((prevState) => ({...prevState, [e.target.name]: e.target.value}));
    };

    const onPhoneCert = async () => {
        if (phoneCertCount > 2) {
            alert('3회 이상 시도하셨습니다. 잠시 후 다시 시도해주세요.')
        }

        if (info.phone && !error.phone) {
            const {data} = await api.post("/sms/certification", {
                phone: info.phone,
            });

            if (data.success) {
                setPhoneCertCount(phoneCertCount + 1);
                setIsSendPhoneCert(true);
            }
        }
    }

    const onClickOpenLink = () => {
        window.open('https://cafe.naver.com/chordscore', "_blank");
    }

    useEffect(() => {
        if (!auth.getToken()) {
            return;
        }
        const fetchData = async () => {
            const {
                data: {data},
            } = await api.get("/user");

            setPayStatus(data.user.pay_status.toString());
            auth.setPayStatus(data.user.pay_status.toString());
            setIsUploader(data.user.is_uploader ? true : false);
        };
        fetchData();
    }, []);

    useHiddenBodyOverflow();

    return (
        <OverlayContainer>
            <Overlay onClick={onClickClose}/>
            <Wrapper>
                <Container>
                    <Header>
                        <LogoWrapper>{renderedLogo}</LogoWrapper>
                        {isSmallScreen && (
                            <LinkIconWrap onClick={onClickOpenLink}/>
                        )}
                        <IconList>
                            {auth.getToken() && (
                                <LogoutIcon onClick={() => history.push("/auth/logout")}>
                                    <Logout/>
                                </LogoutIcon>
                            )}
                            <CancelIcon onClick={onClickClose}>
                                <Cancel/>
                            </CancelIcon>
                        </IconList>
                    </Header>
                    <Content>
                        <Profile
                            style={{padding: isLoggedIn ? "80px 10px" : "56px 10px"}}
                        >
                            {isLoggedIn ? (
                                <>
                                    <EmailText>mustardsauce@naver.com</EmailText>
                                    <EditButton>
                                        <EditIcon>
                                            <Edit/>
                                        </EditIcon>
                                        프로필 수정
                                    </EditButton>
                                </>
                            ) : (
                                <>
                                    {auth.getToken() ? (
                                        <LoginText>
                                            {
                                                auth.getEmail() || "이메일이 등록되지 않았습니다."
                                            }
                                            <LoginSubsTagWrap>
                                                <LoginSubsTag>
                                                    {
                                                        payStatus === '0' && '무료 (악보 열람 가능)'
                                                    }

                                                    {
                                                        payStatus === '3' && '무료 (악보 열람 불가)'
                                                    }

                                                    {
                                                        payStatus === '4' && '유료 (악보 열람 가능, 구독 해지)'
                                                    }

                                                    {
                                                        payStatus === '1' && '유료 (악보 열람 가능)'
                                                    }

                                                    {
                                                        payStatus === '2' && '미결제 (악보 열람 불가)'
                                                    }
                                                </LoginSubsTag>
                                            </LoginSubsTagWrap>
                                        </LoginText>
                                    ) : (
                                        <>
                                            <LoginText>
                                                안녕하세요.
                                                <br/>
                                                코드스코어 입니다.
                                            </LoginText>
                                            <LoginDescription>
                                                회원 서비스 이용을 위해 로그인 해주세요.
                                            </LoginDescription>
                                            <LoginButton onClick={() => history.push("/auth/login")}>
                                                로그인
                                            </LoginButton>
                                        </>
                                    )}
                                </>
                            )}
                        </Profile>
                        {auth.getToken() && (
                            <MenuList>
                                {menuList.map(({menu, auth: isAuth, isUploaderOnly, ...props}) => {
                                    if (!isLoggedIn && isAuth) {
                                        return null;
                                    }
                                    if (isUploaderOnly && !isUploader) {
                                        return null;
                                    }

                                    if (menu === 'FAQ') {
                                        return (
                                            <MenuItem
                                                onClick={() => window.open('https://cafe.naver.com/chordscore/653', '_blank')}
                                                key={`menu-${menu}`}
                                                menu={menu}
                                                isDarkMode={isDarkMode}
                                                isUploaderOnly={isUploaderOnly}
                                                {...props}
                                            />
                                        )
                                    }

                                    if (menu === '구독 신청' && (payStatus === '1' || payStatus === '2' || payStatus === '4')) {
                                        return null;
                                    }

                                    if (menu === '구독 신청' && !(payStatus === '1' || payStatus === '2' || payStatus === '4')) {
                                        return (
                                            <>
                                                <MenuItem
                                                    onClick={() => setShowSubsModal(true)}
                                                    key={`menu-${menu}`}
                                                    menu={menu}
                                                    isDarkMode={isDarkMode}
                                                    isUploaderOnly={isUploaderOnly}
                                                    {...props}
                                                />

                                                {
                                                    showSubsModal ?
                                                        !isSubs ? <Modal
                                                                open={true}
                                                                onClose={() => {
                                                                    history.goBack();
                                                                    setShowSubsModal(false);
                                                                }}
                                                                firstButtonLabel="구독 신청"
                                                                onClickFirstButton={async () => {
                                                                    setIsSubs(true);

                                                                    if (auth.getPhone()) {
                                                                        const {data} = await api.post('/subscribe/add');
                                                                        auth.setPayStatus(data.data.pay_status);
                                                                    }
                                                                }}
                                                                secondButtonLabel="취소"
                                                                onClickSecondButton={() => {
                                                                    history.push('/')
                                                                    setShowSubsModal(false);
                                                                }}
                                                                title="구독 신청"
                                                                content={
                                                                    <>
                                                                        월 3,300원으로 무제한 악보열람 하시겠습니까?
                                                                    </>
                                                                }
                                                            /> :
                                                            auth.getPhone() ?
                                                                <Modal
                                                                    open={true}
                                                                    onClose={() => {
                                                                        history.goBack();
                                                                        setShowSubsModal(false);
                                                                    }}
                                                                    firstButtonLabel="확인"
                                                                    onClickFirstButton={() => {
                                                                        history.push('/');
                                                                        setShowSubsModal(false);
                                                                        window.location.reload();
                                                                    }}
                                                                    title="구독 완료"
                                                                    content={
                                                                        <>
                                                                            구독 신청이 완료 되었습니다. <br/>
                                                                            지금부터 무제한 악보 열람이 가능합니다. <br/>
                                                                            카카오 메세지로 페이소프트(업체명:유아굿) 간편동의 요청이 신청일 기준 2일 이내로 전송 됩니다. <br/>
                                                                            간편동의 해주시면 결제가 진행됩니다. <br/>
                                                                            간편동의 요청 접수 후 3일 내 결제가 이루어지지 않으면 구독 취소 됩니다. <br/>
                                                                            즐거운 연주 되세요.
                                                                        </>
                                                                    }
                                                                /> :
                                                                <Modal
                                                                    open={true}
                                                                    onClose={() => {
                                                                        history.goBack();
                                                                        setIsSubs(false);
                                                                        setShowSubsModal(false);
                                                                    }}
                                                                    firstButtonLabel="확인"
                                                                    onClickFirstButton={async () => {
                                                                        const { data } = await api.post('/phone/change', {
                                                                            phone: info.phone,
                                                                            phoneCert: info.phoneCert
                                                                        });

                                                                        if (data.success) {
                                                                            auth.setPhone(info.phone);
                                                                            alert('변경 되었습니다.');
                                                                            history.push('/');
                                                                            setIsSubs(false);
                                                                            setShowSubsModal(false);
                                                                        } else {
                                                                            alert(data.alert);
                                                                        }
                                                                    }}
                                                                    title="정보 변경"
                                                                    content={
                                                                        <InputWrap>
                                                                            구독 진행하기 위해서는 휴대폰 번호 등록이 필수 입니다.
                                                                            <br/>
                                                                            <br/>
                                                                            <InputWithLabel
                                                                                name="phone"
                                                                                label="휴대폰 번호"
                                                                                placeholder="휴대폰 번호를 입력해주세요."
                                                                                maxLength={11}
                                                                                onChange={handleChange}
                                                                                error={error.phone}
                                                                                errorMessage="올바르지 않은 휴대폰 형식"
                                                                            />
                                                                            {
                                                                                isSendPhoneCert &&
                                                                                <InputWithLabel
                                                                                    name="phoneCert"
                                                                                    label="인증 번호"
                                                                                    placeholder="인증 번호를 입력해주세요."
                                                                                    maxLength={10}
                                                                                    onChange={handleChange}
                                                                                />
                                                                            }
                                                                            <Button styleType="blue" onClick={onPhoneCert}>
                                                                                {
                                                                                    isSendPhoneCert ? '인증번호 재발송' : '휴대폰인증'
                                                                                }
                                                                            </Button>
                                                                        </InputWrap>
                                                                    }
                                                                /> : ''
                                                }
                                            </>
                                        );
                                    }

                                    if (menu === '구독 해지' && (payStatus === '0' || payStatus === '2' || payStatus === '3' || payStatus === '4')) {
                                        return null;
                                    }

                                    if (menu === '구독 해지' && !(payStatus === '0' || payStatus === '2' || payStatus === '3' || payStatus === '4')) {
                                        return (
                                            <>
                                                <MenuItem
                                                    onClick={() => setShowSubsModal(true)}
                                                    key={`menu-${menu}`}
                                                    menu={menu}
                                                    isDarkMode={isDarkMode}
                                                    isUploaderOnly={isUploaderOnly}
                                                    {...props}
                                                />

                                                {
                                                    showSubsModal ?
                                                    !isSubsDel ?
                                                        <Modal
                                                            open={true}
                                                            onClose={() => {
                                                                history.goBack();
                                                                setShowSubsModal(false);
                                                            }}
                                                            firstButtonLabel="구독 해지"
                                                            onClickFirstButton={async () => {
                                                                setIsSubsDel(true);
                                                                const {data} = await api.post('/subscribe/del');
                                                                auth.setPayStatus(data.data.pay_status);
                                                            }}
                                                            secondButtonLabel="취소"
                                                            onClickSecondButton={() => {
                                                                history.push('/')
                                                                setShowSubsModal(false);
                                                            }}
                                                            title="구독 해지"
                                                            content={
                                                                <>
                                                                    구독 해지하시겠습니까?
                                                                </>
                                                            }
                                                        /> :
                                                        <Modal
                                                            open={true}
                                                            onClose={() => {
                                                                history.goBack();
                                                                setShowSubsModal(false);
                                                                setIsSubsDel(false);
                                                            }}
                                                            firstButtonLabel="확인"
                                                            onClickFirstButton={async () => {
                                                                history.push('/');
                                                                window.location.reload();
                                                            }}
                                                            title="구독 해지"
                                                            content={
                                                                <>
                                                                    구독해지 되었습니다. <br/>
                                                                    이용해 주셔서 감사합니다. <br/>
                                                                    마지막 결제일 기준 한달 후 까지 악보열람 가능합니다.
                                                                </>
                                                            }
                                                        /> : ''
                                                }
                                            </>
                                        );
                                    }

                                    return (
                                        <MenuItem
                                            key={`menu-${menu}`}
                                            menu={menu}
                                            isDarkMode={isDarkMode}
                                            isUploaderOnly={isUploaderOnly}
                                            {...props}
                                        />
                                    );
                                })}
                            </MenuList>
                        )}
                        <VersionText>v1.2.1</VersionText>
                    </Content>
                </Container>
            </Wrapper>
        </OverlayContainer>
    );
};

export default SideMenu;

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
`;

const Overlay = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  opacity: 0.83;
  background-color: #111316;
`;

const Wrapper = styled.div`
  z-index: 999;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  @media screen and (max-width: 500px) {
    height: 100%;
    width: 80%;
  }
`;

const Container = styled.div`
  height: 96%;
  width: 500px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;

  html[color-mode="dark"] & {
    background-color: #212632;
  }

  @media screen and (max-width: 960px) {
    width: 430px;
  }

  @media screen and (max-width: 500px) {
    height: 100%;
    width: 100%;
  }
`;

const Header = styled.div`
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(89, 89, 89, 0.15);

  html[color-mode="dark"] & {
    border-bottom: 1px solid #484848;
  }

  @media screen and (max-width: 500px) {
    padding: 22px 26px;
    padding-right: 33px;
  }
`;

const LogoWrapper = styled.div`
  cursor: pointer;

  @media screen and (max-width: 500px) {
    & > svg {
      width: 121px;
      height: 17px;
    }
  }
`;

const IconList = styled.div`
  display: flex;
  align-items: center;

  html[color-mode="dark"] & path {
    fill: white;
  }
`;

const Icon = styled.div`
  cursor: pointer;
`;

const LogoutIcon = styled(Icon)`
  width: 27px;
  height: 27px;
  margin-right: 21px;

  @media screen and (max-width: 500px) {
    width: 18px;
    height: 18px;

    & > svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const CancelIcon = styled(Icon)`
  width: 21px;
  height: 21px;

  @media screen and (max-width: 500px) {
    width: 18px;
    height: 18px;

    & > svg {
      width: 18px !important;
      height: 18px;
    }
  }
`;

const Content = styled.div`
  padding: 40px;
  padding-top: 0;
  height: inherit;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media screen and (max-width: 500px) {
    padding: 30px;
  }
`;

const Profile = styled.div`
  padding-right: 20px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 500px) {
    padding: 50px 0 !important;
    padding-bottom: 40px !important;
  }
`;

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  & > * {
    margin-bottom: 20px;
  }
`;

const EmailText = styled.span`
  opacity: 0.8;
  font-size: 18px;
  line-height: 1.22;
  color: #1e1e1e;

  html[color-mode="dark"] & {
    color: white;
  }
`;

const EditButton = styled.button`
  border-radius: 2px;
  background-color: rgba(115, 125, 135, 0.58);
  padding: 8px 15px;
  border: none;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.38;
  color: #ffffff;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-top: 19px;
`;

const EditIcon = styled(Icon)`
  width: 14px;
  height: 14px;
  margin-right: 6px;
`;

const MenuList = styled.div`
  padding-top: 31.2px;
  border-top: 1px solid rgba(89, 89, 89, 0.15);

  html[color-mode="dark"] & {
    border-top: 1px solid #484848;
  }

  @media screen and (max-width: 500px) {
    &&& {
      border-top: 0;
    }
  }
`;

const VersionText = styled.span`
  font-size: 13px;
  line-height: 1.69;
  color: rgba(0, 0, 0, 0.3);
  margin-top: auto;
  margin-bottom: 8px;
  margin-left: 10px;

  html[color-mode="dark"] & {
    color: rgba(255, 255, 255, 0.3);
  }

  @media screen and (max-width: 500px) {
    margin-bottom: -7px;
    margin-left: 2px;
  }
`;

const LoginText = styled.span`
  color: #1e1e1e;
  font-size: 26px;
  line-height: 1.31;

  html[color-mode="dark"] & {
    color: white;
  }

  @media screen and (max-width: 500px) {
    font-size: 18px;
    line-height: 1.39;
  }
`;

const LoginSubsTag = styled.div`
  background-color: red;
  border-radius: 20px;
  font-size: 14px;
  text-align: center;
  padding: 5px;
  width: 200px;
`

const LoginSubsTagWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
`

const LoginDescription = styled.p`
  opacity: 0.6;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.63;
  color: black;
  margin-top: 10px;

  html[color-mode="dark"] & {
    color: white;
  }

  @media screen and (max-width: 500px) {
    font-size: 13px;
    line-height: 1.69;
    margin-top: 5px;
  }
`;

const LoginButton = styled.button`
  border-radius: 3px;
  background-color: rgba(115, 125, 135, 0.58);
  margin-top: 50px;
  width: 100%;
  padding: 15px 0;
  border: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: #ffffff;

  @media screen and (max-width: 500px) {
    margin-top: 30px;
    font-size: 13px;
    line-height: 1.38;
    padding: 8px 0;
  }
`;

const LinkIconWrap = styled.div`
  width: 37px;
  height: 37px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  right: 20px;
  background-image: url("${LinkIcon}");
  background-size: cover;

  html[color-mode='dark'] & path {
    //fill: white;
  }

  @media screen and (max-width: 1030px) {
    width: 34px;
    height: 34px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
